import React, { useState } from "react";
import "./Player.css";
import ReactPlayer from "react-player";

export default function Player (props)
{
    const [volume, setVolume] = useState(1);

    function toggleVolume ()
    {
        setVolume(volume === 1 ? 0 : 1);
    }

    return (
        <div className="player">
            <button
                className="buttonDefaultPlayer"
                onClick={props.onTogglePlaying}
            >
                {
                    props.playing
                    ? <i className="fas fa-pause iconButtonPlayer"/>
                    : <i className="fas fa-play iconButtonPlayer"/>
                }
            </button>
            <div className="displayPlayer">
                <span>
                    {
                        !props.playing 
                        ? "choose a radio..."
                        : "you are listening " + props.name
                    }
                </span>
            </div>
            <button 
                className="buttonDefaultPlayer"
                onClick={toggleVolume}
            >
                {
                    volume === 1 
                    ? <i className="fas fa-volume-up iconButtonPlayer"/>
                    : <i className="fas fa-volume-mute iconButtonPlayer"/>
                }    
            </button>  
            <ReactPlayer
                url={props.url}
                playing={props.playing}
                style={{display: "none"}}
                volume={volume}
            />
        </div>
    )
}